import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { PerspectivesDiv } from "./elements";
import { H3, H4Big, H6Medium } from "./../Typography";
import ArrowRightLine from "./../icons/ArrowRightLine";

const PerspectiveTile = ({ title, author, image, link }) => {
  return (
    <div>
      <Link to={link}>
        {image && image.cardImage ? (
          image.cardImage.childImageSharp ? (
            <Img fixed={image.cardImage.childImageSharp.fixed} alt={title} />
          ) : (
            <img src={image.cardImage.publicURL} alt={title} />
          )
        ) : (
          <div className="fallback" />
        )}
      </Link>
      <div>
        <H4Big>
          <Link to={link}>{title} </Link>
        </H4Big>
        {author ? (
          <H6Medium>
            <Link to={link}>{author}</Link>
          </H6Medium>
        ) : null}
        <ArrowRightLine color={"var(--green)"} />
      </div>
    </div>
  );
};

const Perspectives = ({ tiles, title, onTop, bottomMargin }) => {
  return (
    <PerspectivesDiv aboveBanner={onTop || false} bottomMargin={bottomMargin}>
      <H3>{title || "Perspectives"}</H3>
      <div>
        {tiles.map((tile, index) => 
        (
          <PerspectiveTile
            key={index}
            title={tile.title}
            author={tile.editorialAuthor || null}
            link={`/${tile.typeHandle}/${tile.slug}`}
            image={
                tile.headerImage && tile.headerImage.length
                ? tile.headerImage[0]
                : (tile.headerImage && tile.headerImage.length ? tile.headerImage[0]: null)
                }
          />
        ))}
      </div>
    </PerspectivesDiv>
  );
};

export default Perspectives;
