import styled from "styled-components";

export const PerspectivesDiv = styled.div`
  --topMargin: "50px";
  --bottomMargin: ${(props) =>
    props.bottomMargin ? props.bottomMargin : "30px"};
  --tileWidth: 400px;
  --textColor: var(--white);
  --iconOpacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  max-width: var(--width);
  margin-bottom: var(--bottomMargin);
  margin-top: var(--topMargin);
  margin-left: 0px;
  margin-right: -20px;
  padding-top: ${(props) =>
    props.aboveBanner ? "25px" : 0}; /* note that this was 100 for front page*/
  & h3 {
    margin-bottom: 20px;
    z-index: 2;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: var(--tileWidth);
      height: 230px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      &:after {
        position: absolute;
        bottom: 0;
        height: 3px;
        left: 0;
        right: 0;
        content: "";
        background-color: var(--green);
        opacity: var(--iconOpacity);
        z-index: 2;
        transition: 0.5s;
      }
      &:hover {
        --borderColor: var(--green);
        --textColor: var(--green);
        --iconOpacity: 1;
      }
      & > a {
        width: 100%;
        height: 100%;
        display: inline-block;
      }
      & img,
      & .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--tileWidth) !important;
        height: 230px !important;
        z-index: 1;
        object-fit: cover;
      }
      & div {
        pointer-events: none;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );
        user-select: none;
        position: absolute;
        padding-bottom: 20px;
        padding-right: 60px;
        padding-left: 20px;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: var(--textColor);
        z-index: 2;
        transition: 0.5s;
        & a {
          color: var(--textColor);
          transition: 0.5s;
        }
        & h6 {
          margin-top: 5px;
        }
        & svg {
          position: absolute;
          right: 20px;
          bottom: 20px;
          transition: 1s;
          opacity: var(--iconOpacity);
        }
      }
    }
  }
  /* @media (min-width: 1441px) { */
  position: relative;
  &:before {
    background-color: var(--white);
    content: "";
    position: absolute;
    left: -2000px;
    right: -2000px;
    top: calc(0px - var(--topMargin));
    bottom: calc(0px - var(--bottomMargin));
    top: -75px; //why?
    /* } */
  }
  @media (max-width: 1023px) {
    & > div {
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    --tileWidth: 375px;
    --topMargin: 25px;
    --bottomMargin: 0;
    /* max-width: var(--tileWidth); */
    margin-right: 0;
    align-items: center;
    & h3 {
      margin-left: 25px;
    }
    & h3.alserkal-title
    {
      margin-left: 0px;
      font-size: 30px;
    }
    & > div {
      max-width: var(--tileWidth);
      justify-content: flex-start;
      & > div {
        margin-right: 0;
        margin-bottom: 25px;
      }
    }
  }
`;
