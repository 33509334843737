import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FoundationWrapper from "./../../components/FoundationWrapper";
import Perspectives from "./../../components/Perspectives";
import { H3Strong } from "./../../components/Typography";
import { SubtitleDiv } from "./../../components/FoundationStyles/elements";

const FoundationProjectsPage = () => {
  const projectsPageQuery = useStaticQuery(graphql`
    {
      craftFoundationProjectsFoundationProjectsEntry {
        title
        subtitle
        foundationPerspectives {
          slug
          title
          typeHandle
          ... on Craft_articles_words_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_sounds_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_event_event_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).craftFoundationProjectsFoundationProjectsEntry;
  // console.log(projectsPageQuery);
  // console.log(projectsPageQuery.perspectives.map((x) => x.headerImage));
  return (
    <FoundationWrapper
      title={projectsPageQuery.title}
      selected
      selection={2}
      useStayInformed={true}
    >
      <SubtitleDiv>
        <H3Strong>{projectsPageQuery.subtitle}</H3Strong>
      </SubtitleDiv>
      <div
        style={{
          backgroundColor: "var(--white)",
          padding: "50px 100px 0 100px",
        }}
      >
        <Perspectives
          tiles={projectsPageQuery.foundationPerspectives}
          title={" "}
          bottomMargin={"80px"}
        />
      </div>
    </FoundationWrapper>
  );
};

export default FoundationProjectsPage;
